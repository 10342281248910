import * as React from "react"
import { useCallback, useMemo } from "react"
import { BoxProps, Flex } from "@chakra-ui/react"
import { graphql, useStaticQuery } from "gatsby"
import CustomLink from "../common/CustomLink"
import { SanityFooter } from "../../graphql/global/footerFragment"
import { BsFacebook, BsInstagram } from "react-icons/bs"
import { SanitySocialMediaItem } from "../../graphql/objects/socialMediaItemFragment"
import Icon from "../common/Icon"

type Query = {
  footer: SanityFooter
}

const Footer: React.FC<BoxProps> = ({ ...props }) => {
  const { footer } = useStaticQuery<Query>(graphql`
    query {
      footer: sanityFooter {
        ...FOOTER_FRAGMENT
      }
    }
  `)

  const getIcon = useCallback((item: SanitySocialMediaItem) => {
    switch (item?.type) {
      case "facebook":
        return <BsFacebook />
      case "instagram":
        return <BsInstagram />
      default:
        return undefined
    }
  }, [])

  const items = useMemo(() => {
    return footer?.socialMedia?.map(item => ({
      _key: item?._key,
      icon: getIcon(item),
      url: item?.url,
    }))
  }, [footer?.socialMedia])

  return (
    <Flex {...props}>
      {items?.map(item => (
        <CustomLink key={item?._key} layout="custom" url={item?.url} external={true} w="24px" h="24px" mr={4}>
          <Icon icon={item?.icon} />
        </CustomLink>
      ))}
    </Flex>
  )
}

export default React.memo(Footer)
