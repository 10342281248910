import * as React from "react"
import { ReactNode } from "react"
import { Box, BoxProps, Container } from "@chakra-ui/react"
import { SanitySectionDisplaySettings } from "../../graphql/objects/sectionDisplaySettingsFragment"
import { useSectionDisplaySettings } from "../../hooks/useSectionDisplaySettings"

type Props = {
  displaySettings?: SanitySectionDisplaySettings
  children: ReactNode
} & BoxProps

const SubSectionContainer: React.FC<Props> = ({ displaySettings, children, ...props }) => {
  const { maxW, px } = useSectionDisplaySettings(displaySettings)

  return (
    <Container maxW={maxW} px={0} {...props}>
      <Box px={px}>
        {children}
      </Box>
    </Container>
  )
}

export default React.memo(SubSectionContainer)
