export const Switch = {
  baseStyle: {
    track: {
      _checked: {
        bgColor: "primary",
      },
    },
  },
  sizes: {},
  variants: {},
  defaultProps: {},
}
