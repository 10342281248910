import * as React from "react"
import { Box, Flex, Text } from "@chakra-ui/react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { SanitySiteSettings } from "../../graphql/settings/siteSettingsFragment"
import CustomImage from "../common/CustomImage"
import CustomLink from "../common/CustomLink"
import { SanityFooterItem } from "../../graphql/objects/footerItemFragment"
import { SanityFooter } from "../../graphql/global/footerFragment"
import { SanityOrganisationSettings } from "../../graphql/settings/organisationSettingsFragment"
import RichText from "../common/RichText"
import FooterSocialMedia from "./FooterSocialMedia"
import SubSectionContainer from "../sections/SubSectionContainer"
import { useAppContext } from "../../providers/app"

type Query = {
  site: SanitySiteSettings
  organisation: SanityOrganisationSettings
  footer: SanityFooter
}

const Footer: React.FC = () => {
  const { showHeader } = useAppContext()
  const { site, organisation, footer } = useStaticQuery<Query>(graphql`
    query {
      site: sanitySiteSettings {
        ...SITE_SETTINGS_FRAGMENT
      }
      organisation: sanityOrganisationSettings {
        ...ORGANISATION_SETTINGS_FRAGMENT
      }
      footer: sanityFooter {
        ...FOOTER_FRAGMENT
      }
    }
  `)

  return showHeader ? (
    <Box color="textLight" bgColor="primary">
      <SubSectionContainer py={6}>
        <Flex flexDir={{ base: "column", md: "row" }}>
          <Box order={{ base: 1, md: 0 }} mt={{ base: 5, md: 0 }}>
            <Flex
              maxW={{ base: "500px", md: "300px" }}
              alignItems="center"
              display={{ base: "flex", md: "block" }}
            >
              <CustomImage
                as={Link}
                to="/"
                image={site?.logo}
                w={{ base: "80px", md: "120px" }}
                srcSetWidth={120}
                objectFit="contain"
                display="block"
              />
              <Text size="xs" flex={1} mt={{ md: "20px" }} ml={{ base: "20px", md: 0 }}>
                {footer?.description}
              </Text>
            </Flex>
            <FooterSocialMedia mt={5} />
          </Box>
          <Flex order={{ base: 0, md: 1 }} flex={{ md: 1 }} ml={{ md: "40px" }}>
            <Box flex={1}>
              <Text variant="bold" mb={3}>
                {footer?.sitemapLabel}
              </Text>
              {footer?.footerItems?.map((item: SanityFooterItem) => (
                <Box key={item._key} mb={1}>
                  <CustomLink layout="inline" link={item?.link} />
                </Box>
              ))}
            </Box>
            <Box flex={{ base: 1, md: "2 1 200px" }} ml={{ md: "60px" }}>
              <Text variant="bold" mb={3}>
                {footer?.contactLabel}
              </Text>
              <RichText richText={organisation?.address} />
            </Box>
          </Flex>
        </Flex>
      </SubSectionContainer>
    </Box>
  ) : null
}

export default React.memo(Footer)
