import * as React from "react"
import { useMemo } from "react"
import { Box } from "@chakra-ui/react"
import CustomLink from "../common/CustomLink"
import { SanityHeaderItem } from "../../graphql/objects/headerItemFragment"
import { useAppContext } from "../../providers/app"

type Props = {
  item: SanityHeaderItem
  dropdownVisible: boolean
  location: Location
  onDropdownTrigger?: () => void
  onDropdownDismiss?: () => void
}

const HeaderItem: React.FC<Props> = ({
  item,
  dropdownVisible,
  location,
  onDropdownTrigger,
  onDropdownDismiss,
}) => {
  const { pageAtTop } = useAppContext()
  const dropdownEnabled = false

  const isSelected = useMemo(
    () => location?.pathname?.startsWith(`/${item?.link?.internalLink?.handle?.current}`),
    [item?.link?.internalLink?.handle, location?.pathname]
  )

  const style = useMemo(
    () =>
      isSelected
        ? {
            bgColor: "primarySelected",
            borderRadius: "999px",
          }
        : {},
    [isSelected]
  )

  return (
    <Box
      key={item._key}
      pos="relative"
      borderRadius="999px"
      ml={5}
      onMouseEnter={() => onDropdownTrigger?.()}
      _hover={{
        bgColor: isSelected ? "primarySelected" : "primaryHover",
      }}
      {...style}
    >
      <CustomLink layout="inline" link={item?.link} size="lg" fontWeight={500} px={5} py={2} />
      {/*<Box*/}
      {/*  h="9999px"*/}
      {/*  pos="absolute"*/}
      {/*  top={`calc(100% + ${pageAtTop ? 38 : 16}px)`}*/}
      {/*  left="-9999px"*/}
      {/*  right="-9999px"*/}
      {/*  display={dropdownVisible ? "block" : "none"}*/}
      {/*  onMouseMove={() => onDropdownDismiss?.()}*/}
      {/*/>*/}
      {/*{dropdownEnabled && (*/}
      {/*  <Box*/}
      {/*    textAlign="left"*/}
      {/*    bgColor="accent1"*/}
      {/*    w="150px"*/}
      {/*    p={4}*/}
      {/*    pos="absolute"*/}
      {/*    top={`calc(100% + ${pageAtTop ? 38 : 16}px)`}*/}
      {/*    left="-30px"*/}
      {/*    transform={`scaleY(${dropdownVisible ? 100 : 0}%)`}*/}
      {/*    transformOrigin="top"*/}
      {/*    transition="0.2s top, 0.2s transform, 0.2s opacity"*/}
      {/*    opacity={dropdownVisible ? 1 : 0}*/}
      {/*    pointerEvents={dropdownVisible ? "all" : "none"}*/}
      {/*    zIndex={1}*/}
      {/*  >*/}
      {/*    /!* Header items go here *!/*/}
      {/*  </Box>*/}
      {/*)}*/}
    </Box>
  )
}

export default React.memo(HeaderItem)
