import { Input } from "./Input"

export const Select = {
  baseStyle: {
    field: {
      ...Input.baseStyle.field,
    },
  },
  sizes: {
    default: {},
  },
  variants: {
    default: {
      field: Input.variants.default.field,
    },
  },
  defaultProps: {
    variant: "default",
  },
}
