import * as React from "react"
import { useMemo } from "react"
import CustomLink from "../common/CustomLink"
import { SanityHeaderItem } from "../../graphql/objects/headerItemFragment"
import { Box } from "@chakra-ui/react"

type Props = {
  item: SanityHeaderItem
  onItemClick?: () => void
}

const MenuDrawerItem: React.FC<Props> = ({ item, onItemClick }) => {
  const isSelected = useMemo(
    () => location?.pathname?.startsWith(`/${item?.link?.internalLink?.handle?.current}`),
    [item?.link?.internalLink?.handle]
  )

  return (
    <CustomLink
      layout="custom"
      link={item?.link}
      size="md"
      px={1}
      py={2}
      display="block"
      onClick={onItemClick}
    >
      <Box bgColor={isSelected ? "accent1Hover" : "accent1"} borderRadius="999px" px={5} py={2}>
        {item?.link?.title}
      </Box>
    </CustomLink>
  )
}

export default React.memo(MenuDrawerItem)
