import { useCallback, useMemo } from "react"
import {
  SanityPadding,
  SanitySectionDisplaySettings,
} from "../graphql/objects/sectionDisplaySettingsFragment"

export const useSectionDisplaySettings = (displaySettings?: SanitySectionDisplaySettings) => {
  const getHorizontalPadding = useCallback((value?: SanityPadding) => {
    switch (value) {
      case "none":
        return 0
      default:
      case "medium":
        return 8
    }
  }, [])

  const getVerticalPadding = useCallback((value?: SanityPadding) => {
    switch (value) {
      case "small":
        return "20px"
      case "medium":
        return "40px"
      case "large":
        return "60px"
      default:
        return 0
    }
  }, [])

  const maxW = useMemo(() => {
    switch (displaySettings?.horizontalMargin) {
      default:
      case "constrained":
        return "container.desktop"
      case "fullWidth":
        return "auto"
    }
  }, [displaySettings?.horizontalMargin])

  const px = useMemo(() => {
    return {
      base: getHorizontalPadding(
        displaySettings?.mode === "granularResponsive" || displaySettings?.mode === "responsive"
          ? displaySettings?.paddingHorizontalMobile
          : displaySettings?.paddingHorizontal
      ),
      md: getHorizontalPadding(
        displaySettings?.mode === "granularResponsive" || displaySettings?.mode === "responsive"
          ? displaySettings?.paddingHorizontalDesktop
          : displaySettings?.paddingHorizontal
      ),
    }
  }, [displaySettings])

  const pt = useMemo(() => {
    return {
      base: getVerticalPadding(
        displaySettings?.mode === "granularResponsive"
          ? displaySettings?.paddingTopMobile
          : displaySettings?.mode === "responsive"
            ? displaySettings?.paddingVerticalMobile
            : displaySettings?.mode === "granular"
              ? displaySettings?.paddingTop
              : displaySettings?.paddingVertical
      ),
      md: getVerticalPadding(
        displaySettings?.mode === "granularResponsive"
          ? displaySettings?.paddingTopDesktop
          : displaySettings?.mode === "responsive"
            ? displaySettings?.paddingVerticalDesktop
            : displaySettings?.mode === "granular"
              ? displaySettings?.paddingTop
              : displaySettings?.paddingVertical
      ),
    }
  }, [displaySettings])

  const pb = useMemo(() => {
    return {
      base: getVerticalPadding(
        displaySettings?.mode === "granularResponsive"
          ? displaySettings?.paddingBottomMobile
          : displaySettings?.mode === "responsive"
            ? displaySettings?.paddingVerticalMobile
            : displaySettings?.mode === "granular"
              ? displaySettings?.paddingBottom
              : displaySettings?.paddingVertical
      ),
      md: getVerticalPadding(
        displaySettings?.mode === "granularResponsive"
          ? displaySettings?.paddingBottomDesktop
          : displaySettings?.mode === "responsive"
            ? displaySettings?.paddingVerticalDesktop
            : displaySettings?.mode === "granular"
              ? displaySettings?.paddingBottom
              : displaySettings?.paddingVertical
      ),
    }
  }, [displaySettings])

  return {
    maxW,
    px,
    pt,
    pb,
  }
}
