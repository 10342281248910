export const Input = {
  parts: ["field"],
  baseStyle: {
    field: {},
  },
  sizes: {},
  variants: {
    default: {
      field: {
        bgColor: "background",
        border: "1px solid",
        borderColor: "grey",
        borderRadius: "20px",
        outlineColor: "transparent",
        _focus: {
          outline: "2px solid",
          outlineColor: "primary",
          transition: "outline-color 0.5s",
        },
        _invalid: {
          borderColor: "error",
          _focus: {
            outlineColor: "error",
          },
        },
        _placeholder: { color: "textMuted" },
      },
    },
  },
  defaultProps: {
    variant: "default",
  },
}
