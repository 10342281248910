export const Badge = {
  baseStyle: {
    textAlign: "center",
    borderRadius: "999px",
    px: 2,
  },
  variants: {},
  sizes: {},
  defaultProps: {},
}
