import * as React from "react"
import { useMemo } from "react"
import { BoxProps, Heading } from "@chakra-ui/react"

type Props = {
  level: number
} & BoxProps

const ResponsiveHeading: React.FC<Props> = ({ level, children, ...props }) => {
  const size = useMemo(() => {
    switch (level) {
      case 1:
        return { base: `3xl`, md: `4xl` }
      case 2:
        return { base: `2xl`, md: `3xl` }
      case 3:
        return { base: `xl`, md: `2xl` }
      case 4:
        return { base: `lg`, md: `xl` }
      default:
        return {}
    }
  }, [level])

  return (
    <Heading size={size} {...props}>
      {children}
    </Heading>
  )
}

export default React.memo(ResponsiveHeading)
