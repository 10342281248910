import { useEffect, useMemo } from "react"
import { graphql, navigate, useStaticQuery } from "gatsby"
import { SanityMaintenanceSettings } from "../graphql/settings/maintenanceSettingsFragment"
import config from "../../config"

type Query = {
  maintenance: SanityMaintenanceSettings
}

export const useMaintenance = () => {
  const { maintenance } = useStaticQuery<Query>(graphql`
    query {
      maintenance: sanityMaintenanceSettings {
        ...MAINTENANCE_SETTINGS_FRAGMENT
      }
    }
  `)

  const isMaintenanceEnabled = useMemo(() => {
    if (typeof window === "undefined") {
      return maintenance?.enableMaintenance
    }
    return (
      maintenance?.enableMaintenance &&
      window.btoa(maintenance?.sitePassword || "") !==
        localStorage.getItem(config.localStorage.MAINTENANCE_AUTH)
    )
  }, [maintenance?.enableMaintenance, maintenance?.sitePassword])

  useEffect(() => {
    if (isMaintenanceEnabled) {
      navigate("/")
    }
  }, [isMaintenanceEnabled])

  return {
    isMaintenanceEnabled,
  }
}
