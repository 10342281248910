import * as React from "react"
import { useAppContext } from "../../providers/app"
import { FaPaw } from "react-icons/fa"
import Icon from "../common/Icon"

const MenuIcon: React.FC = () => {
  const { setActiveDrawer } = useAppContext()

  return (
    <Icon
      icon={<FaPaw />}
      p={2}
      w="40px"
      h="40px"
      display="inline-block"
      cursor="pointer"
      onClick={() => setActiveDrawer("menu")}
    />
  )
}

export default React.memo(MenuIcon)
