import { Input } from "./Input"

export const Textarea = {
  baseStyle: {
    ...Input.baseStyle.field,
  },
  sizes: {},
  variants: {
    default: {
      ...Input.variants.default.field,
    },
  },
  defaultProps: {
    variant: "default",
  },
}
