export const FormError = {
  parts: ["text"],
  baseStyle: {
    text: {
      color: "error",
    },
  },
  sizes: {},
  variants: {},
  defaultProps: {},
}
