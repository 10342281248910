import { extendTheme } from "@chakra-ui/react"
import Heading from "./components/Heading"
import Text from "./components/Text"
import Button from "./components/Button"
import colors from "./colors"
import { Badge } from "./components/Badge"
import { Input } from "./components/Input"
import { Textarea } from "./components/Textarea"
import { Select } from "./components/Select"
import { FormError } from "./components/FormError"
import { Divider } from "./components/Divider"
import { sizes } from "./sizes"
import { Tooltip } from "./components/Tooltip"
import { Checkbox } from "./components/Checkbox"
import { Spinner } from "./components/Spinner"
import { Switch } from "./components/Switch"

const theme = {
  colors,
  sizes,
  components: {
    Heading,
    Text,
    Checkbox,
    Divider,
    Button,
    Badge,
    Input,
    Spinner,
    Switch,
    Textarea,
    Tooltip,
    Select,
    FormError,
  },
  styles: {
    global: () => ({
      "html > body": {
        fontFamily: "Quicksand, sans-serif",
        bgColor: "white",
      },
    }),
  },
}

export default extendTheme(theme)
