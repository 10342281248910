import * as React from "react"
import { useMemo } from "react"
import { Box, Button, ButtonProps, Text, TextProps } from "@chakra-ui/react"
import { SanityLink } from "../../graphql/objects/linkFragment"
import { Link } from "gatsby"

type Props = {
  layout: "button" | "inline" | "custom"
} & ButtonProps &
  TextProps &
  (
    | {
        link?: SanityLink
        url?: never
        external?: never
      }
    | {
        link?: never
        url?: string
        external?: boolean
      }
  )

const CustomLink: React.FC<Props> = ({ layout, link, url, external, children, ...props }) => {
  const finalUrl = useMemo(() => {
    if (link?.type === "internal") {
      switch (link?.internalLink?._type) {
        case "homepage":
          return "/"
        case "page":
          return `/${link?.internalLink?.handle?.current}`
      }
    }

    if (link?.type === "external") {
      return link?.externalLink
    }

    return url || null
  }, [link, url])

  const linkProps: any = useMemo(
    () =>
      (link?.type === "external" && link?.external) || external
        ? { as: "a", href: finalUrl || "", target: "_blank" }
        : { as: Link, to: finalUrl || "" },
    [external, finalUrl, link?.external, link?.type]
  )

  return finalUrl ? (
    <>
      {layout === "button" ? (
        <Button {...props} {...linkProps}>
          {link?.title || children}
        </Button>
      ) : layout === "inline" ? (
        <Text {...props} {...linkProps}>
          {link?.title || children}
        </Text>
      ) : layout === "custom" ? (
        <Box {...props} {...linkProps}>
          {children}
        </Box>
      ) : null}
    </>
  ) : null
}

export default React.memo(CustomLink)
