const secondaryStyle = {
  color: "textDark",
  bgColor: "accent1",
  borderColor: "accent1",
  _hover: {
    bgColor: "accent1Hover",
    borderColor: "accent1Hover",
  },
}

const neutralStyle = {
  color: "textDark",
  bgColor: "background",
  borderColor: "grey",
  _hover: {
    bgColor: "greyLight",
    borderColor: "textMuted",
  },
}

const smStyle = {
  fontSize: "14px",
  h: 10,
  py: 0,
}

export default {
  baseStyle: {
    fontFamily: "Quicksand, sans-serif",
    fontSize: "16px",
    fontWeight: 500,
    letterSpacing: 0.5,
    lineHeight: "16px",
    textAlign: "center",
    textTransform: "uppercase",
    whiteSpace: "break-spaces",
    borderRadius: "999px",
    borderWidth: "1px",
    minW: "150px",
    minWidth: "150px",
    py: 6,
  },
  variants: {
    primary: {
      color: "textLight",
      bgColor: "primary",
      borderColor: "primary",
      _hover: {
        bgColor: "primaryHover",
        borderColor: "primaryHover",
        _disabled: {
          bgColor: "primary",
          borderColor: "primary",
        },
      },
    },
    secondary: {
      ...secondaryStyle,
    },
    secondarySm: {
      ...secondaryStyle,
      ...smStyle,
    },
    secondaryIcon: {
      ...secondaryStyle,
      minWidth: 0,
      w: 8,
      h: 8,
      p: 1,
    },
    tertiary: {
      color: "textLight",
      bgColor: "accent2",
      borderColor: "accent2",
    },
    destructive: {
      color: "textLight",
      bgColor: "error",
      borderColor: "error",
      _hover: {
        _disabled: {
          bgColor: "error",
          borderColor: "error",
        },
      },
    },
    neutral: {
      ...neutralStyle,
    },
    neutralSm: {
      ...neutralStyle,
      ...smStyle,
    },
    neutralXs: {
      ...neutralStyle,
      h: 8,
      py: 0,
    },
    link: {
      color: "primary",
      bgColor: "transparent",
      textDecoration: "underline",
      textTransform: "none",
      border: "none",
      letterSpacing: 0,
      minW: "unset",
      minWidth: "unset",
      py: 0,
    },
  },
  sizes: {},
  defaultProps: {
    variant: "primary",
  },
}
