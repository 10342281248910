import { useMemo } from "react"
import { SanityHorizontalAlign } from "../graphql/global/fieldTypes"

export const useHorizontalFlexAlign = (align?: SanityHorizontalAlign, def: SanityHorizontalAlign = "left") => {
  return useMemo(() => {
    switch (align) {
      case "left":
        return "flex-start"
      case "center":
        return "center"
      case "right":
        return "flex-end"
    }

    return def
  }, [align, def])
}
