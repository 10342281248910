import * as React from "react"
import { useEffect, useState } from "react"
import { Box, Center, Container, Text } from "@chakra-ui/react"

type Props = {
  lines?: Array<string>
}

const Announcement: React.FC<Props> = ({ lines }) => {
  const [activeIndex, setActiveIndex] = useState(0)

  useEffect(() => {
    if (lines?.length) {
      const interval = setInterval(() => {
        setActiveIndex(active => (active + 1) % lines?.length)
      }, 3000)
      return () => clearInterval(interval)
    }
  }, [lines?.length])

  return (
    <Box bgColor="accent1" h="40px">
      <Container maxW="container.desktop" h="100%" pos="relative">
        {lines?.map((line, index) => (
          <Center
            key={index}
            color="black"
            pos="absolute"
            insetX={4}
            insetY={0}
            opacity={index === activeIndex ? 1 : 0}
            pointerEvents={index == activeIndex ? "auto" : "none"}
            transition="0.2s"
          >
            <Text variant="announcement">{line}</Text>
          </Center>
        ))}
      </Container>
    </Box>
  )
}

export default React.memo(Announcement)
