import React, { ReactNode, useEffect, useMemo, useState } from "react"
import config from "../../config"
import { useFirebaseContext } from "./firebase"

type ActiveDrawer = "menu" | null

type ContextProps = {
  activeDrawer: ActiveDrawer
  setActiveDrawer: React.Dispatch<React.SetStateAction<ActiveDrawer>>
  pageAtTop: boolean
  showHeader: boolean
}

export const AppContext = React.createContext<ContextProps | null>(null)

type Props = {
  children: ReactNode
  location: Location
}

export const AppProvider: React.FC<Props> = ({ children, location }) => {
  const { user } = useFirebaseContext()
  const [activeDrawer, setActiveDrawer] = useState<ActiveDrawer>(null)
  const [pageAtTop, setPageAtTop] = useState(true)

  const showHeader = useMemo(() => {
    const pathname = location?.pathname
    if (
      pathname?.startsWith(config.routes.ACCOUNT) &&
      !(
        pathname?.startsWith(config.routes.LOGIN) ||
        pathname?.startsWith(config.routes.FORGOT_PASSWORD) ||
        pathname?.startsWith(config.routes.RESET_PASSWORD)
      )
    ) {
      return !!user?.role && !["owner", "admin"].includes(user?.role || "")
    }

    return true
  }, [location?.pathname, user?.role])

  useEffect(() => {
    const handleScroll = () =>
      setPageAtTop(typeof window !== "undefined" ? window.scrollY === 0 : true)
    handleScroll()
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", handleScroll, { passive: true })
    }
    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("scroll", handleScroll)
      }
    }
  }, [])

  const contextValue = useMemo<ContextProps>(
    () => ({
      activeDrawer,
      setActiveDrawer,
      pageAtTop,
      showHeader,
    }),
    [activeDrawer, pageAtTop, showHeader]
  )

  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
}

export const useAppContext = (): ContextProps =>
  ({ ...React.useContext(AppContext) }) as ContextProps
