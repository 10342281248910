export const Checkbox = {
  baseStyle: {
    control: {
      padding: 2,
      rounded: "full",
      _checked: {
        bgColor: "primary",
        borderColor: "primary",
      },
    },
    icon: {},
  },
  sizes: {},
  variants: {},
  defaultProps: {},
}
