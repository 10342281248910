import * as React from "react"
import { css, Global as EmotionGlobal } from "@emotion/react"

const Global: React.FC = () => {
  return (
    <EmotionGlobal
      styles={css`
        @import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600&family=Quicksand:wght@400;500;600&display=swap");
        .grecaptcha-badge {
          display: none !important;
        }
      `}
    />
  )
}

export default React.memo(Global)
