export default {
  baseStyle: {
    color: "inherit",
    fontFamily: "Lora, serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "16px",
  },
  variants: {
    tab: {
      fontFamily: "Quicksand, sans-serif",
      fontSize: { base: "14px", md: "20px" },
      lineHeight: "36px",
    },
  },
  sizes: {
    "4xl": {
      fontSize: "53px",
      lineHeight: "58.3px",
    },
    "3xl": {
      fontSize: "40px",
      lineHeight: "44px",
    },
    "2xl": {
      fontSize: "32px",
      lineHeight: "35.2px",
    },
    xl: {
      fontSize: "24px",
      lineHeight: "26px",
    },
    lg: {
      fontSize: "20px",
      lineHeight: "22px",
    },
  },
  defaultProps: {
    size: "4xl",
  },
}
