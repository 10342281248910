import * as React from "react"
import Image from "gatsby-plugin-sanity-image"
import { SanityCustomImage } from "../../graphql/objects/customImageFragment"
import { Box, BoxProps } from "@chakra-ui/react"
import { SanityImageFit } from "../../graphql/global/fieldTypes"

type Props = {
  to?: string
  image?: SanityCustomImage
  srcSetWidth?: number
  objectFit?: SanityImageFit
} & BoxProps

const CustomImage: React.FC<Props> = ({ image, srcSetWidth, objectFit, ...props }) => {
  return image?.asset?.asset ? (
    <Box
      sx={{ img: { width: "100%", height: "100%", objectFit: objectFit || "cover" } }}
      {...props}
    >
      <Image
        {...image?.asset}
        alt={image?.alt || ""}
        {...(srcSetWidth ? { width: srcSetWidth } : {})}
      />
    </Box>
  ) : null
}

export default React.memo(CustomImage)
