import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { doc, Firestore, getFirestore, onSnapshot } from "firebase/firestore"
import { Auth, getAuth } from "firebase/auth"
import { initializeApp } from "firebase/app"
import config from "../../config"
import { AuthUser, FirestoreUserInternalSettings, UserRole } from "../types/firebaseTypes"
import { docToFirestoreUserInternalSettings } from "../utils/firestoreDocConverters"

type AuthState = "authed" | "determining" | "unauthed"

type ContextProps = {
  firestore: Firestore
  auth: Auth
  authState: AuthState
  isAdmin: boolean
  user: AuthUser | null
  settings: FirestoreUserInternalSettings | null
}

export const FirebaseContext = React.createContext<ContextProps | null>(null)

export const FirebaseProvider: React.FC<any> = ({ children }) => {
  const unsubscribeRef = useRef<(() => void) | null>(null)
  const [isUserLoading, setIsUserLoading] = useState(true)
  const [user, setUser] = useState<AuthUser | null>(null)
  const [settings, setSettings] = useState<FirestoreUserInternalSettings | null>(null)

  const app = useMemo(() => {
    return initializeApp(config.firebase.appConfig)
  }, [])

  const firestore = useMemo(() => getFirestore(app), [app])

  const auth = useMemo(() => getAuth(app), [app])

  const authState: AuthState = useMemo(() => {
    if (user) {
      return "authed"
    } else if (isUserLoading) {
      return "determining"
    } else {
      return "unauthed"
    }
  }, [isUserLoading, user])

  const isAdmin = useMemo(() => ["owner", "admin"].includes(user?.role || ""), [user?.role])

  const fetchSettings = useCallback(
    (id: string) => {
      unsubscribeRef.current = onSnapshot(
        doc(
          firestore,
          config.firestore.collections.USERS,
          id,
          config.firestore.collections.INTERNAL,
          config.firestore.documents.SETTINGS
        ),
        doc => {
          setSettings(docToFirestoreUserInternalSettings(doc))
        },
        () => {
          setUser(null)
        }
      )
    },
    [firestore]
  )

  useEffect(() => {
    const auth = getAuth(app)
    auth.onAuthStateChanged(user => {
      unsubscribeRef?.current?.()

      setIsUserLoading(true)
      setUser(null)
      setSettings(null)

      if (user) {
        user
          .getIdTokenResult(true)
          .then(result => {
            setIsUserLoading(false)

            const role = (result?.claims?.role || "") as string
            if (["owner", "admin", "pawrent"].includes(role)) {
              setUser({
                ...user,
                role: role as UserRole,
                token: result.token,
              })

              if (role !== "pawrent") {
                fetchSettings(user.uid)
              }
            }
          })
          .catch(() => {
            setUser(null)
          })
      } else {
        setIsUserLoading(false)
      }
    })
  }, [app, fetchSettings])

  useEffect(() => {
    return () => {
      unsubscribeRef?.current?.()
    }
  }, [])

  const contextValue = useMemo<ContextProps>(
    () => ({
      firestore,
      auth,
      authState,
      isAdmin,
      user,
      settings,
    }),
    [firestore, auth, authState, isAdmin, user, settings]
  )

  return <FirebaseContext.Provider value={contextValue}>{children}</FirebaseContext.Provider>
}

export const useFirebaseContext = (): ContextProps =>
  ({ ...React.useContext(FirebaseContext) }) as ContextProps
